<template>
  <ListaParametros
    url="/admin/traducoes"
    descPlural="Traduções"
    descSingular="Tradução"
  />
</template>
<script>

import ListaParametros from '../Parametros/ListaParametros';

export default {
  name: "traducoes",
  components: {
    ListaParametros,
  },
};
</script>