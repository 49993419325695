<style scoped>
    .subtitle {
        margin-bottom: 10px;
    }
</style>
<template>
    <div class="card-body">
        <div class="xrow">
            <div v-for="sistema in sistemasPermitidos" v-bind:key="sistema" class="col col-1-3">
                <div class="thumbnail BorderActive">
                    <div class="subtitle ">
                      Sistema: <strong class="label dark">{{ sistema.toUpperCase() }}</strong>
                    </div>
                    <div class="LogoimgBox">
                        <img :src="imageUrl(sistema)" />
                    </div>
                    <div class="caption">
                        <div class="caption__actions">
                            <label class="button success">
                                Alterar Imagem
                                <input type="file" name="image" style="display: none;" accept="image/jpg, image/jpeg, image/png" @change="handleUpload(sistema)"/>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import _reduce from 'lodash/reduce';

export default {
  name: 'backgrounds',
  props: [
    'baseUrl',
    'sistemas',
    'upload',
    'hash'
  ],
  computed: {
    sistemasPermitidos() {
      return _reduce(this.sistemas, (acc, sistema) => {
        if (this.sistemasDefault.indexOf(sistema.chave) >= 0) {
          acc.push(sistema.chave);
        }
        return acc;
      }, []);
    },
  },
  data() {
    return {
      sistemasDefault: [
        'B2B',
        'CALLCENTER',
        'ADMIN',
        'SAC'
      ],
      hashs: {
        B2B: null,
        CALLCENTER: null,
        ADMIN: null,
        SAC: null,
      },
    };
  },
  methods: {
    imageUrl(sistema) {
      return this.baseUrl + sistema + '?no-cache=' + this.hashs[sistema];
    },
    handleUpload(sistema) {
      this.upload('background', { sistema }).then((response) => {
        this.hashs[sistema] = this.hash();
      });
    },
  },
};
</script>
