<template>
    <div>
        <div class="xrow">
            <div class="col col-1-3">
                <div class="thumbnail BorderActive">
                    <div class="subtitle">
                        Logo dos apps, notas e boletos.
                        <br>
                        Tamanho recomendado <strong class="label success">250 x 100 px</strong>
                    </div>
                    <div class="LogoimgBox">
                        <img :src="logoUrl" />
                    </div>
                    <div class="caption">
                        <div class="caption__actions">
                            <label class="button success">
                                Alterar Logo
                                <input type="file" name="image" style="display: none;" accept="image/jpg, image/jpeg, image/png" @change="upload('logo')"/>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col col-1-3">
                <div class="thumbnail BorderActive">
                    <div class="subtitle">
                        Ícone dos apps
                        <br>
                        Tamanho recomendado <strong class="label success">512 x 512 px</strong>
                    </div>
                    <div class="LogoimgBox">
                        <img :src="faviconUrl" />
                    </div>
                    <div class="caption">
                        <div class="caption__actions">
                            <label class="button success">
                                Alterar Ícone
                                <input type="file" name="image" style="display: none;" accept="image/jpg, image/jpeg, image/png" @change="upload('favicon')"/>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'logos',
  props: ['upload', 'logoUrl', 'faviconUrl'],
};
</script>
