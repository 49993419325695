<template>
    <modal name="ModalQrCode" height="auto" width="600" :scrollable="true"  :clickToClose="false"  >
        <CloseModal :close="onClose" />
        <div class="modalbox">
          <div class="modalbox-content text-center" >
              <div class="row justify-content-md-center">
                <div class="form-group">
                  <LoadingMessage
                    message="Gerando QrCode"
                    :is-active="isLoading"
                    align-center
                  />
                  <div class="alert alert-danger text-left" v-if="!!error">
                    <strong>Falha ao gerar QrCode:</strong>
                    <br>
                    {{ error }}
                  </div>
                  <div class="col-md-auto" v-if="dataQrCode.qrcode">
                    <img :src="'data:image/jpeg;base64,' + dataQrCode.qrcode" >
                  </div>
                </div>
              </div>
          </div>
        </div>
      <div class="container text-left">
        <div class="row">
          <div class="col">
            <h2><strong> Para usar o VersoVendas no seu dispositivo móvel:</strong></h2>
            <p>1 - Abra o aplicativo VersoVendas</p>
            <p>2 - No modo de configuração, selecione "Ler de QRCode"</p>
            <p>3 - Aponte a câmera do dispotivo para o Qrcode para capturar o código</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="button" v-on:click="onClose">Fechar</button>
      </div>
    </modal>

</template>

<script>
import CloseModal from "../../../components/CloseModal";

export default {
  name: "ModalQrCode",
  components: { CloseModal  },
  props: [
    "isLoading",
    "dataQrCode",
    "onClose",
    "error"
  ],
  data() {
    return {
      loading: false
    };
  },
};
</script>