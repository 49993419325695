var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Loading',{attrs:{"isActive":_vm.loading}}),_c('CTabs',{staticClass:"nav-tabs-boxed",attrs:{"variant":"tabs"}},[(
        _vm.loadAuthorizationAction(
          'ADMIN_CONFIGURACAO',
          'CONFIGURACOES_AVANCADO'
        )
      )?_c('CTab',{attrs:{"title":"Avançado"}},[_c('h2',[_vm._v("As configurações aqui existentes foram mescladas com a aba de parâmetros")]),_c('router-link',{attrs:{"to":"/configuracoes/parametros"}},[_vm._v("Clique aqui para acessar")])],1):_vm._e(),(
        _vm.loadAuthorizationAction(
          'ADMIN_CONFIGURACAO',
          'CONFIGURACOES_INTEGRACOES'
        )
      )?_c('CTab',{attrs:{"title":"Integrações"}},[_c('Integracoes')],1):_vm._e(),(
        _vm.loadAuthorizationAction(
          'ADMIN_CONFIGURACAO',
          'CONFIGURACOES_TRADUCOES'
        )
      )?_c('CTab',{attrs:{"title":"Traduções"}},[_c('Traducoes')],1):_vm._e(),(
        _vm.loadAuthorizationAction('ADMIN_CONFIGURACAO', 'CONFIGURACOES_LOGOS')
      )?_c('CTab',{attrs:{"title":"Logos"}},[_c('Logos',{attrs:{"logoUrl":_vm.images.logo,"faviconUrl":_vm.images.favicon,"upload":_vm.handleUpload}})],1):_vm._e(),(
        _vm.loadAuthorizationAction(
          'ADMIN_CONFIGURACAO',
          'CONFIGURACOES_IMAGENS_FUNDO'
        )
      )?_c('CTab',{attrs:{"title":"Imagens de Fundo"}},[_c('Backgrounds',{attrs:{"baseUrl":_vm.images.background,"sistemas":_vm.sistemas,"hash":_vm.makeHash,"upload":_vm.handleUpload}})],1):_vm._e(),(
        _vm.loadAuthorizationAction('ADMIN_CONFIGURACAO', 'CONFIGURACOES_TEMAS')
      )?_c('CTab',{attrs:{"title":"Temas"}},[_c('Temas',{attrs:{"temas":_vm.temas,"salvarTema":_vm.salvarTema}})],1):_vm._e(),(
        _vm.temSistemaFV &&
        _vm.loadAuthorizationAction(
          'ADMIN_CONFIGURACAO',
          'CONFIGURACOES_FORCA_VENDAS'
        )
      )?_c('CTab',{attrs:{"title":"Força de Vendas"}},[_c('ForcaVendas')],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }