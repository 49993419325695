<template>
  <div>
    <Loading :isActive="loading" />
    <ModalGerenciarVersoes
      :representantes="representantes"
      :onSubmit="salvar"
      :onClose="closeGerenciar"
    />
    <ModalQrCode
      :is-loading="loading"
      :error="error"
      :onClose="closeModalQrCode"
      :dataQrCode="dataQrCode"
    />
    <div class="text-right py-2">
      <CButton color="success" size="sm" @click="openGerenciar()">
        Gerenciar Versões
      </CButton>
    </div>
    <div class="table-responsive">
      <datatable
        :columns="table.columns"
        :data="representantes"
        :filter-by="table.filter"
        class="table table-hover"
      >
        <template slot-scope="{ row }">
          <tr>
            <td align="center">{{ row.id_exibicao }}</td>
            <td align="center">{{ row.tipo }}</td>
            <td align="center">{{ row.nome }}</td>
            <td align="center">{{ row.versao_fv }}</td>
            <td align="center">{{ row.versao_fv_atual }}</td>
            <td class="actions">
                <CButton color="primary" size="sm" @click="openModalQrCode(row.id)"> Gerar</CButton>
            </td>
          </tr>
        </template>
      </datatable>
    </div>
  </div>
</template>

<script>
  import { get, put } from '@/helpers/apiRequest'
  import ModalGerenciarVersoes from './ForcaVendas/ModalGerenciarVersoes'
  import ModalQrCode from './ForcaVendas/ModalQrCode'

  export default {
    name: "forcavendas",
    components: {
      ModalGerenciarVersoes, ModalQrCode
    },
    data () {
      return {
        loading: false,
        representantes: [],
        dataQrCode: {},
        error: null,
        table: {
          filter: "",
          columns: [
            { label: "Cód", field: "id_exibicao", align: "center", sortable: true},
            { label: "Tipo", field: "tipo", align: "center", sortable: true},
            { label: "Nome", field: "nome", align: "center", sortable: true},
            { label: "Versão Sel.", field: "versao_fv_numerico", align: "center", sortable: true},
            { label: "Versão Disp.", field: "versao_fv_atual_numerico", align: "center", sortable: true },
            { label: "Gerar QrCode:", field: "qrCode", align: "center", sortable: false },
          ],
          paginator: {},
          paginatorAguardando: {}
        }
      }
    },
    methods: {
      openGerenciar() {
        this.$modal.show("ModalGerenciarVersoes");
      },
      closeGerenciar() {
        this.$modal.hide("ModalGerenciarVersoes");
      },
      openModalQrCode(id) {
        this.gerarQrCode(id);
        this.$modal.show("ModalQrCode");
      },
      closeModalQrCode() {
        this.$modal.hide("ModalQrCode");
      },
      salvar(data) {
        this.loading = true

        put('/admin/forca-vendas/atualizar-versoes/representantes', data)
          .then(() => this.loadRepresentantes())
          .finally(() => this.loading = false)
      },
      loadRepresentantes() {
        this.loading = true

        get('/admin/forca-vendas/representantes')
            .then(json => this.representantes = json)
            .finally(() => {
              this.loading = false
              this.closeGerenciar()
            })
      },
      gerarQrCode(colaboradorId){
        this.loading = true;
        this.dataQrCode = {};
        this.error = null;

        get('/admin/forca-vendas/qr-code/'+ colaboradorId)
          .then(json => this.dataQrCode = json)
          .catch(error => this.error = error.message)
          .finally(() => this.loading = false);
      },
    },
    beforeMount () {
      this.loadRepresentantes()
    }
  };
</script>