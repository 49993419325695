<template>
  <ListaParametros
    url="/admin/configuracoes"
    descPlural="Integrações"
    descSingular="Integração"
  />
</template>
<script>

import ListaParametros from '../Parametros/ListaParametros';

export default {
  name: 'integracoes',
  components: {
    ListaParametros,
  },
};
</script>
