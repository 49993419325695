<template>
    <modal name="ModalGerenciarVersoes" height="auto" width="1024" :scrollable="true" :clickToClose="false" @before-open="loadVersoes">
        <CloseModal :close="onClose" />
        <div class="modalbox">
            <div class="modalbox-content">
                <div class="xrow">
                    <div class="col-1-4">
                        <img :src="logoFv" class="logo-bd-vendas"/>
                    </div>
                    <div class="col-3-4" v-if="versaoSelecionada.versao">
                        <div class="xrow">
                            <div class="col-3-4">
                                <h2 class="title">Versão selecionada: {{versaoSelecionada.versao}}</h2>
                                <div class="version-subtitle">
                                    <strong>Publicado em:</strong>
                                    <span>{{moment(versaoSelecionada.datahora_pub).format("DD/MM/YY \à\\s HH:MM")}}</span>
                                </div>
                            </div>
                            <div class="col-1-4">
                                <a class="button button-success" :href="versaoSelecionada.link" target="_blank">
                                    <fa-icon :icon='"download"'/>
                                    Baixar .apk
                                </a>
                            </div>
                        </div>
                        <div class="notes">
                            <vue-markdown :source="versaoSelecionada.detalhes"/>
                        </div>
                    </div>
                </div>
                <div class="xrow">
                    <div class="col-1-3">
                        <div class="scrollable list-container" style="height: 400px;">
                            <table class="table table-hover table-versions">
                                <thead>
                                <tr>
                                    <th>
                                        <div class="header">
                                            <span>Versões Disponíveis</span>
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="versao in versoes">
                                    <td>
                                        <div :class='clsx("select-version", {active: versaoIsChecked(versao)})'
                                             v-on:click="versaoSelecionada = versao">
                                            <div>
                                                <div>
                                                    <strong class="select-version__title">Versão: </strong>
                                                    <span>{{versao.versao}}</span>
                                                    <span class="version-info"
                                                          v-if="!!versao.padrao"> (Padrão atual)</span>
                                                </div>
                                                <div>
                                                    <strong class="select-version__title">Publicado em: </strong>
                                                    <div class="select-version__subtitle">
                                                        {{moment(versao.datahora_pub).format("DD/MM/YY \à\\s HH:MM")}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <fa-icon
                                                        :icon='["far", versaoIsChecked(versao) ? "check-square":"square"]'/>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-1-3">
                        <div class="scrollable list-container" style="height: 400px;">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>
                                        <div class="header">
                                            <span>Representantes Disponíveis</span>
                                            <button
                                                    class="button button-success"
                                                    title="Adicionar todos"
                                                    :disabled="!representantesFiltered.length"
                                                    v-on:click="addTodos">
                                                <fa-icon icon="angle-double-right"/>
                                            </button>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        <input
                                                type="text"
                                                v-model="filter"
                                                class="form-control"
                                                placeholder="Pesquisar representante"
                                        >
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="representante in representantesFiltered" v-bind:key="representante.id">
                                    <td>
                                        <div class="line">
                                            <div>
                                                <span class="pre-text">ID: {{ representante.id_exibicao }}</span>
                                                <h5 class="main-text">{{ representante.nome }}</h5>
                                                <h5 class="main-text">Versão atual: {{ representante.versao_fv }}</h5>
                                            </div>
                                            <div class="actions">
                                                <button
                                                        v-on:click="addRepresentante(representante)"
                                                        class="button button-success"
                                                >
                                                    <fa-icon icon="angle-right"/>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="col-1-3">
                        <div class="scrollable list-container" style="height: 400px">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>
                                        <div class="header">
                                            <span>Representantes Selecionados</span>
                                            <button class="button button-error"
                                                    :disabled="!representantesSelecionados.length"
                                                    title="Remover todos"
                                                    v-on:click="removeTodos">
                                                <fa-icon icon="angle-double-left"/>
                                            </button>
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="representante in representantesSelecionados"
                                    v-bind:key="representante.id">
                                    <td>
                                        <div class="line">
                                            <div>
                                                <span class="pre-text">ID: {{ representante.id_exibicao }}</span>
                                                <h5 class="main-text">{{ representante.nome }}</h5>
                                                <h5 class="main-text">Versão atual: {{ representante.versao_fv }}</h5>
                                            </div>
                                            <span class="actions">
                                                    <button
                                                            type="button"
                                                            name="button"
                                                            v-on:click="removeRepresentante(representante.id)"
                                                            class="button button-error"
                                                    >
                                                      <fa-icon icon="angle-left"/>
                                                    </button>
                                                </span>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <div class="modal-footer">
            <p v-on:click="padrao = !padrao">
                <fa-icon :icon='["far", !!padrao ? "check-square":"square"]'/>
                <strong> Tornar versão padrão para as futuras instalações</strong>
            </p>
            <button class="button button-success" v-on:click="handleSubmit" :disabled="!representantesSelecionados.length">Atualizar</button>
            <button class="button" v-on:click="onClose">Fechar</button>
        </div>
    </modal>
</template>

<style scoped>
div {
  user-select: none;
}

img {
  pointer-events: none;
}

.title {
  margin-bottom: 2px;
  padding-bottom: 0px;
}

.version-subtitle {
  font-size: 14px;
}

.notes {
  margin-top: 16px;
}

.list-container {
  border: 1px solid #ccc;
}

.header {
  display: flex;
  justify-content: space-between;
  height: 30px;
}

.table.table-versions td,
.table.table-versions tr {
  border: none;
  padding: 8px 8px 0 8px;
}

.select-version {
  display: flex;
  width: 100%;
  margin-bottom: 4px;
  justify-content: space-between;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
}

.select-version.active {
  background-color: #5eb95e;
  color: #fff;
}

.select-version:hover {
  opacity: 0.7;
}

.form-actions p {
  margin-bottom: 16px;
  cursor: pointer;
}

.line {
  display: flex;
  justify-content: space-between;
}

.version-info {
  font-size: 10px;
}

.select-version__title {
  font-size: 12px;
}

.select-version__subtitle {
  font-size: 12px;
}

.logo-bd-vendas {
  max-width: 150px;
}
</style>
<script>
import { get } from "@/helpers/apiRequest";
import _find from "lodash/find";
import _filter from "lodash/filter";
import _remove from "lodash/remove";
import _map from "lodash/map";
import _get from "lodash/get";
import _values from "lodash/values";
import VueMarkdown from "vue-markdown";
import moment from "moment";
import clsx from "clsx";
import logoFv from "../../../assets/images/logo-verso-vendas.png";
import { normalizeString } from "@/helpers/utils";
import CloseModal from "../../../components/CloseModal";

export default {
  name: "ModalGerenciarVersoes",
  components: {
    VueMarkdown,
    CloseModal,
  },
  props: ["onSubmit", "onClose", "representantes"],
  data() {
    return {
      loading: false,
      versoes: [],
      versaoSelecionada: {},
      padrao: false,
      representantesSelecionados: [],
      filter: "",
      logoFv,
    };
  },
  methods: {
    loadVersoes() {
      this.resetState();
      this.loading = false;

      get("/admin/forca-vendas/versoes")
        .then((json) => {
          this.versoes = json;
          this.versaoSelecionada = _find(json, { padrao: 1 }) || {};
        })
        .finally(() => (this.loading = false));
    },
    addRepresentante(representante) {
      this.representantesSelecionados.push(representante);
    },
    removeRepresentante(id) {
      const arrayAux = [...this.representantesSelecionados];
      _remove(arrayAux, (representante) => {
        return representante.id === id;
      });
      this.representantesSelecionados = arrayAux;
    },
    addTodos() {
      this.representantesSelecionados = this.representantes;
    },
    removeTodos() {
      this.representantesSelecionados = [];
    },
    versaoIsChecked(versao) {
      return versao.versao === this.versaoSelecionada.versao;
    },
    handleSubmit() {
      const normalizedData = {
        representantes: _map(this.representantesSelecionados, (rep) =>
          _get(rep, "id")
        ),
        versao: this.versaoSelecionada.versao,
        padrao: this.padrao,
      };

      this.onSubmit(normalizedData);
    },
    resetState() {
      this.versaoSelecionada = {};
      this.representantesSelecionados = [];
      this.padrao = false;
      this.filter = "";
    },
    clsx,
    moment,
  },
  computed: {
    representantesFiltered() {
      const repAux = _filter(this.representantes, (representante) => {
        return !_find(this.representantesSelecionados, {
          id: representante.id,
        });
      });

      if (!this.filter) return repAux;

      const termo = normalizeString(this.filter.toLowerCase());
      return _filter(repAux, (rep) => {
        const stringRep = _values(rep)
          .join(" ")
          .toLowerCase();
        return stringRep.indexOf(termo) >= 0;
      });
    },
  },
};
</script>
